import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { PLACEMENTS_DB } from "~/components/ui/Product/ProductHeader/PanelEmbroidery/EmbroideryForm/EmbroideryPlacementSelector/constants"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { text } from "~/styles/variants"

export type EmbroideryPlacementProps = PropsWithClassName<{
  placement: string | null
}>

function EmbroideryPlacement({ className, placement }: EmbroideryPlacementProps) {
  const t = useTranslate()
  const label = isPlacementDB(placement) ? PLACEMENTS_DB[placement].label : null

  if (!label) return null

  return (
    <ClientTranslate
      as="li"
      tKey="embroidery_placement"
      className={clsx(
        className,
        text({
          design: "riposte-10",
          color: "blue-light",
        })
      )}
    >
      {` : ${t(label)}`}
    </ClientTranslate>
  )
}

function isPlacementDB(placement: string | null): placement is keyof typeof PLACEMENTS_DB {
  if (!placement) return false
  return placement in PLACEMENTS_DB
}

export default EmbroideryPlacement
